import React, { useState, useCallback, useEffect } from 'react';
import { Label, Input, Table, CustomInput } from 'reactstrap';
import { ErrorActionPayload } from '../../../models/ErrorAction';
import { Vessel } from '../../../store/Vessels/models';
import { Loading } from '../../Loading';
import SearchVessel from '../../SearchVessel/SearchVessel';
import { Error } from '../../Error';
import { ICompany } from '@teqplay/chorus-components';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from '../../../store/Auth/actions';
import { getChorusCompanies } from '../../../store/ChorusCompany/actions';
import { ApplicationState } from '../../../store';
import { AddToast } from '../../../helpers/toastService';
import { companyFleetClearToast } from '../../../store/CompanyFleet/actions';
import FuelBossTableContainer from '../../FuelBossTable';
import { Column } from 'react-table/index';
import DeleteById from '../../DeleteById/DeleteById';
import { UserVessel } from '../../../store/MyFleet/models';
import { FleetVessel } from '../../../store/CompanyFleet/models';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledTableWrapper } from '../StyledComponents/StyledTableWrapper';
import editButtonStyles from '../../../components/EditById/EditById.module.scss';
import deleteButtonStyles from '../../../components/DeleteById/DeleteById.module.scss';
import searchStyles from '../../../components/SearchVessel/SearchVessel.module.scss';
import { EditBunkership } from '../../EditBunkership/EditBunkership';
import { MyFleets } from '../../../store/MyFleets/models';
import MyFleetCard from '../../../scenes/Map/Components/MyFleetContainerMap/Components/MyFleetCard/MyFleetCard';
import fleetCardStyles from '../../../scenes/Map/Components/MyFleetContainerMap/Components/MyFleetCard/MyFleetCard.module.scss';
import arrowUp from '../../../scenes/Map/Components/MyFleetContainerMap/Components/MyFleetCard/arrow-up.png';
import { CustomError } from '../../Error/components/CustomError';
import { User } from '../../../store/Users/models';
import SearchVesselCustomized from '../../SearchVessel/SearchVesselCustomized';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import { getFleetVessels } from '../../../store/CompanyFleet/actions';
import classnames from 'classnames';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getCompanies } from '../../../store/Company/actions';
import { Company } from '../../../store/Company/models';
import styled from 'styled-components';
import { FleetCharterer } from '../../../store/CompanyCharteredFleet/models';
import SearchCharterVessel from '../../SearchVessel/SearchCharterVessel';
import { addCharteredFleetVessel, companyCharteredFleetClearToast } from '../../../store/CompanyCharteredFleet/actions';
import { checkCompanyAdmin, checkSupplier } from '../../../helpers/roleChecks';

const penIcon = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

const StyledCustomSwitch = styled.div`
  .custom-switch .custom-control-label:after {
    background-color: #b4b4b4;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border: 1px solid #24365c;
  }
`;

interface OwnProps {
  companyFleet?: any[];
  myFleets?: MyFleets[];
  newFleet?: boolean;
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteFleet?: (id: string) => void;
  addFleet?: (name: string) => void;
  deleteVesselMethod: (id: string) => void;
  chooseVesselMethod: (vessel: Vessel, fleetId?: string) => void;
  noPagination?: boolean;
  numberOfRowsPerPagination?: number;
  mapMyFleet?: boolean;
  className?: string;
  displaySearchVesselCustomized?: boolean;
  setDisplaySearchVesselCustomized?: (display: boolean) => void;
  isSearchVesselCustomizedActive?: boolean;
  setSearchVesselCustomizedActive?: (isActive: boolean) => void;
  serviceLayer: AuthenticationServiceLayer;
  editVesselMethod?: (vessel: FleetVessel) => void;
  setCharterableFleetVesselMethod?: (isCharterable: boolean, vessel: FleetVessel) => void;
  companyCharteredFleet?: FleetCharterer[];
  addCharteredFleetVesselMethod?: (vessel: FleetVessel) => void;
  deleteCharteredFleetVesselMethod?: (id: string) => void;
}

const MyFleet: React.FC<OwnProps> = ({
  companyFleet,
  myFleets,
  newFleet,
  isLoading,
  hasError,
  errorDetails,
  deleteFleet,
  addFleet,
  deleteVesselMethod,
  chooseVesselMethod,
  numberOfRowsPerPagination = 8,
  noPagination = false,
  mapMyFleet = false,
  className,
  displaySearchVesselCustomized = true,
  setDisplaySearchVesselCustomized,
  isSearchVesselCustomizedActive,
  setSearchVesselCustomizedActive,
  serviceLayer,
  editVesselMethod,
  setCharterableFleetVesselMethod,
  companyCharteredFleet,
  addCharteredFleetVesselMethod,
  deleteCharteredFleetVesselMethod,
}) => {
  const {
    authLoading,
    authError,
    authErrorDetails,
    auth,
    authIsLoaded,
    chorusCompanyLoading,
    chorusCompanyError,
    chorusCompanyErrorDetails,
    chorusCompany,
    chorusCompanyIsLoaded,
    toastData,
    toastDataCharteredFleet,
    companiesLoading,
    companiesDetailsError,
    companiesErrorDetails,
    companies,
    companiesIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
    chorusCompanyLoading: state.choruscompany.loading,
    chorusCompanyError: state.choruscompany.error,
    chorusCompanyErrorDetails: state.choruscompany.errorDetails,
    chorusCompany: state.choruscompany.data,
    chorusCompanyIsLoaded: state.choruscompany.dataIsLoaded,
    toastData: state.companyFleet.toastData,
    toastDataCharteredFleet: state.companyCharteredFleet.toastData,
    companiesLoading: state.companies.loading,
    companiesDetailsError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
  }));

  const [vendorCompanies, setVendorCompanies] = useState<ICompany[]>();
  const [selectedBunkerShipId, displayEditBunkerFor] = useState<string>('');
  const [fleetName, setFleetName] = useState('');
  const isCompanyAdmin = checkCompanyAdmin(auth);

  const chooseVessel = (vessel: Vessel, fleetId?: string) => {
    chooseVesselMethod(vessel, fleetId);
  };

  useEffect(() => {
    setVendorCompanies(chorusCompany.filter((e: ICompany) => e.companyType.toUpperCase() === 'VENDOR'));
  }, [chorusCompany]);

  useEffect(() => {
    setSuggestions(companies);
  }, [companies]);

  const dispatch = useDispatch();

  //Callbacks to redux
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);
  const getCompaniesCallback = useCallback(() => dispatch(getChorusCompanies()), []);
  const clearToastCallback = useCallback(() => dispatch(companyFleetClearToast()), []);
  const clearCharteredFleetToastCallback = useCallback(() => dispatch(companyCharteredFleetClearToast()), []);

  const [editOwnerVesselId, displayEditOwnerVesselFor] = useState<string>('');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [companySuggestions, setSuggestions] = useState<Company[]>([]);
  const [chosenOwner, setChosenOwner] = useState('');
  const [chosenOwnerObj, setChosenOwnerObj] = useState<Company>();
  const [activeCompanySuggestion, setActiveCompanySuggestion] = useState(0);

  const [addOwnerModal, setAddOwnerModal] = React.useState(false);
  const toggleAddOwnerModal = () => setAddOwnerModal(!addOwnerModal);
  const getAllFuelbossCompaniesCallback = useCallback(() => dispatch(getCompanies()), []);

  const chooseOwner = (vessel: FleetVessel, company: Company) => {
    setChosenOwnerObj(company);
    setActiveCompanySuggestion(0);
    setChosenOwner(company.name);
    setSuggestions([]);
  };

  const addOwnerClick = (vessel: FleetVessel) => {
    let selectedVessel = vessel;
    if (chosenOwnerObj && editVesselMethod && chosenOwnerObj.chorusId) {
      selectedVessel.ownerCompany = chosenOwnerObj.chorusId;
      editVesselMethod(selectedVessel);
    }
    displayEditOwnerVesselFor('');
    setChosenOwner('');
    toggleAddOwnerModal();
  };

  const isAddOwnerButtonDisabled = () => {
    const selectedOwner = companies.filter((company) => company.name == chosenOwner);
    if (selectedOwner.length > 0) {
      return false;
    }
    return true;
  };

  const onOwnerChange = (val: string) => {
    setActiveCompanySuggestion(0);
    setChosenOwner(val);
    setSuggestions(companies.filter((company) => company.name.toLowerCase().indexOf(val.toLowerCase()) > -1));
  };

  const onOwnerFocus = (val: string) => {};
  const onOwnerBlur = (val: string) => {};

  const getCompanyFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId) {
      dispatch(getFleetVessels(auth.company.companyId));
    }
  }, []);

  //On mount load everything if not loaded
  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
    if (!chorusCompanyIsLoaded) {
      getCompaniesCallback();
    }
    if (!companiesIsLoaded && !mapMyFleet) {
      getAllFuelbossCompaniesCallback();
    }
  }, []);

  useEffect(() => {
    if ((toastData != null || toastData != undefined) && !errorDetails) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  useEffect(() => {
    if (toastDataCharteredFleet != null || toastDataCharteredFleet != undefined) {
      AddToast(toastDataCharteredFleet);
      clearCharteredFleetToastCallback();
    }
  }, [toastDataCharteredFleet]);

  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, vessel: FleetVessel) => {
    const checked = event.target.checked;
    if (setCharterableFleetVesselMethod && isCompanyAdmin) {
      setCharterableFleetVesselMethod(checked, vessel);
    }
  };

  const addCharteredFleetVesselCallback = React.useCallback((vessel: FleetVessel, allowShipOwnerToCreateNominations: boolean) => {
    if (auth.company && auth.company.companyId) dispatch(addCharteredFleetVessel(auth.company.companyId, allowShipOwnerToCreateNominations, vessel));
  }, []);
  
  const onChangeSwitchAllowShipOwnerToCreateNominations = (event: React.ChangeEvent<HTMLInputElement>, fleetCharterer: FleetCharterer) => {
    const checked = event.target.checked;
    if(fleetCharterer.fleetVessel && isCompanyAdmin){
      addCharteredFleetVesselCallback(fleetCharterer.fleetVessel, checked);
    }
  };

  const isSupplier = auth.company && auth.company.category.categoryName.toLowerCase() === 'supplier' ? true : false;
  const headersNew: Array<Column<UserVessel | FleetVessel>> = React.useMemo(
    () => [
      {
        Header: 'IMO',
        accessor: (row) => {
          if (row.vessel.imo) return row.vessel.imo;
          else return '';
        },
      },
      {
        Header: 'Name',
        accessor: (row) => {
          if (row.vessel.vesselName) return row.vessel.vesselName;
          else return '';
        },
      },
      {
        Header: 'Type',
        accessor: (row) => {
          if (row.vessel.shipType1) return row.vessel.shipType1;
          else return '';
        },
      },
      /* {
        Header: 'Owner',
        Cell: (props: any) => {
          const vessel = props.cell.row.original;
          if (vessel.chorusId == editOwnerVesselId) {
            return (
              <>
                <div className="owner-cell" style={{ display: 'flex' }}>
                  <div className={fleetCardStyles.addFleetStyle}>
                    <Input
                      style={{
                        borderRadius: 'unset',
                        width: '170px',
                        height: '38px',
                        fontWeight: 'normal',
                        fontSize: '15px',
                        marginRight: '7px',
                      }}
                      type="text"
                      id="ownerCompanyName"
                      name="fleetList"
                      autoComplete="off"
                      placeholder="Add Owner company"
                      key={'newOwnerInput' + vessel.vessel.name}
                      value={chosenOwner || ''}
                      onChange={(e) => onOwnerChange(e.target.value)}
                      onFocus={(e) => onOwnerFocus(e.target.value)}
                      onBlur={(e) => onOwnerBlur(e.target.value)}
                      autoFocus
                    />
                    <Button
                      className={fleetCardStyles.buttonStyle}
                      color="primary"
                      //onClick={(e) => addOwnerClick(vessel)}
                      onClick={(e) => toggleAddOwnerModal()}
                      id={chosenOwner}
                      disabled={!chosenOwner.length && isAddOwnerButtonDisabled()}
                      size="lg"
                      // @ts-ignore
                      style={StyledButton}
                    >
                      Add
                    </Button>
                    <Modal isOpen={addOwnerModal} toggle={toggleAddOwnerModal}>
                      <ModalHeader toggle={toggleAddOwnerModal}>Confirmation</ModalHeader>
                      <ModalBody>
                        Are you sure you want to add {chosenOwnerObj?.name} as the owner of {vessel.vessel.vesselName}?
                        This will enable the owner to access all the nominations and quotations related to this vessel.
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={(e) => addOwnerClick(vessel)}>
                          Yes
                        </Button>{' '}
                        <Button color="secondary" onClick={toggleAddOwnerModal}>
                          No
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </div>
                <ul className={searchStyles.suggestions}>
                  {chosenOwner.length > 1 &&
                    companySuggestions.map((company: Company, index: any) => (
                      <li
                        onClick={() => chooseOwner(vessel, company)}
                        className={classnames(
                          activeCompanySuggestion === index ? searchStyles.active : '',
                          searchStyles.suggestion,
                        )}
                      >
                        <div className={'CompanyListName'}>{company.name}</div>
                      </li>
                    ))}
                </ul>
              </>
            );
          } else if (!vessel.ownerCompany) {
            return (
              <div className="owner-cell" style={{ display: 'flex' }}>
                <Button
                  className={editButtonStyles['btn-edit']}
                  style={{
                    //@ts-ignore
                    fontWeight: '500',
                    width: '100px',
                  }}
                  onClick={() => {
                    displayEditOwnerVesselFor(vessel.chorusId);
                  }}
                >
                  Add owner
                </Button>
              </div>
            );
          } else {
            const ownerCompany = companies.filter((x) => x.chorusId == vessel.ownerCompany)[0];
            return (
              <div className="owner-cell" style={{ display: 'flex' }}>
                <div style={{ width: '170px', display: 'flex', alignItems: 'center' }}>
                  {ownerCompany ? ownerCompany.name : '-'}
                </div>
                <Button
                  className={editButtonStyles['btn-edit']}
                  style={{
                    //@ts-ignore
                    fontWeight: '500',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    displayEditOwnerVesselFor(vessel.chorusId);
                  }}
                >
                  Edit
                </Button>
              </div>
            );
          }
        },
      }, */
      {
        Header: 'Chartered by',
        Cell: (props: any) => {
          const vessel = props.cell.row.original;
          return (
            <ul className={''} style={{ listStyleType: 'none', margin: '0', padding: '0' }}>
              {vessel.fleetCharterers.map((fleetCharterer: FleetCharterer, index: any) => {
                const selectedCharterer = companies.filter(
                  (company) => company.companyId == fleetCharterer.chartererCompanyId,
                );
                return (
                  <li>
                    <div>
                      {selectedCharterer.length > 0 ? selectedCharterer[0].name : fleetCharterer.chartererCompanyId}
                    </div>
                  </li>
                );
              })}
            </ul>
          );
        },
      },
      {
        Header: 'Searchable for charterers',
        maxWidth: 100,
        Cell: (props: any) => {
          const vessel = props.cell.row.original;
          return (
            <StyledCustomSwitch>
              <CustomInput
                type="switch"
                id={vessel.chorusId}
                name={vessel.chorusId}
                label=""
                onChange={(e) => onChangeSwitch(e, vessel)}
                checked={vessel.charterable}
              />
            </StyledCustomSwitch>
          );
        },
      },
      // not used after seperate option to specify quality
      /* {
        Header: 'LNG quality specification',
        Cell: (props: any) => {
          const vessel = props.cell.row.original;
          return (
            <div className="delete-cell">
              {isSupplier && vessel.vessel.vesselType.toLowerCase() === 'bunkervessel' && (
                <Button
                  className={editButtonStyles['btn-edit']}
                  onClick={() => {
                    displayEditBunkerFor(vessel.chorusId);
                  }}
                >
                  <FontAwesomeIcon icon={penIcon} />
                </Button>
              )}
            </div>
          );
        },
      }, */
      ...(isCompanyAdmin || isSupplier
        ? [
            {
              Header: 'Delete',
              Cell: (props: any) => {
                const data = props.cell.row.original;
                return (
                  <div className="delete-cell">
                    <DeleteById
                      id={data.id}
                      deleteMethod={deleteVesselMethod}
                      header="Delete vessel"
                      delBtnIcon={deleteIcon}
                    />
                  </div>
                );
              },
            },
          ]
        : []),
    ],
    [editOwnerVesselId, chosenOwner, chorusCompany, companies, addOwnerModal],
  );

  const headersChartered: Array<Column<FleetCharterer>> = React.useMemo(
    () => [
      {
        Header: 'IMO',
        accessor: (row) => {
          if (row.fleetVessel && row.fleetVessel.vessel.imo) return row.fleetVessel.vessel.imo;
          else return '';
        },
      },
      {
        Header: 'Name',
        accessor: (row) => {
          if (row.fleetVessel && row.fleetVessel.vessel.vesselName) return row.fleetVessel.vessel.vesselName;
          else return '';
        },
      },
      {
        Header: 'Type',
        accessor: (row) => {
          if (row.fleetVessel && row.fleetVessel.vessel.shipType1) return row.fleetVessel.vessel.shipType1;
          else return '';
        },
      },
      {
        Header: 'Owner',
        accessor: (row) => {
          if (row.fleetVessel && row.fleetVessel.company) return row.fleetVessel.company.name;
          else return '';
        },
      },
      {
        Header: 'Allow Shipowner to create nominations',
        maxWidth: 100,
        Cell: (props: any) => {
          const charterer = props.cell.row.original;
          const type = props.cell.row.original.fleetVessel.vessel.shipType1;
          // dont allow this option if the chartered vessel is a bunker vessel
          if (type.toLowerCase().includes("bunker vessel")) {
            return (<></>);
          }
          return (
            <StyledCustomSwitch>
              <CustomInput
                type="switch"
                id={charterer.id}
                name={charterer.id}
                label=""
                onChange={(e) => onChangeSwitchAllowShipOwnerToCreateNominations(e, charterer)}
                checked={charterer.allowShipOwnerToCreateNominations}
              />
            </StyledCustomSwitch>
          );
        },
      },
      ...(isCompanyAdmin
        ? [
            {
              Header: 'Delete',
              Cell: (props: any) => {
                const fleetCharterer = props.cell.row.original;
                return (
                  <div className="delete-cell">
                    <Button
                      className={deleteButtonStyles['btn-del']}
                      onClick={() => {
                        if (deleteCharteredFleetVesselMethod) deleteCharteredFleetVesselMethod(fleetCharterer.id);
                      }}
                    >
                      <FontAwesomeIcon icon={deleteIcon} />
                    </Button>
                  </div>
                );
              },
            },
          ]
        : []),
    ],
    [],
  );
  const StyledButton: React.CSSProperties = { display: 'flex', alignItems: 'center', zIndex: 1 };

  const addFleetClick = () => {
    if (addFleet != undefined) {
      addFleet(fleetName);
      setFleetName('');
    }
  };

  const newFleetItem = () => {
    if (newFleet) {
      return (
        <div className={fleetCardStyles.newCard}>
          <div className={fleetCardStyles.newHeader}>Add new fleet list</div>
          <div className={fleetCardStyles.addFleetStyle}>
            <Input
              style={{
                borderRadius: 'unset',
                height: '38px',
                fontWeight: 'normal',
                fontSize: '15px',
                marginRight: '2px',
              }}
              type="text"
              id="fleetListName"
              name="fleetList"
              autoComplete="off"
              placeholder="Fleet list name"
              key="newFawefwleet"
              value={fleetName || ''}
              onChange={(e) => {
                setFleetName(e.target.value);
              }}
            />
            <Button
              className={fleetCardStyles.buttonStyle}
              color="primary"
              onClick={addFleetClick}
              disabled={!fleetName.length}
              size="lg"
              // @ts-ignore
              style={StyledButton}
            >
              Add
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  if (isLoading || authLoading || chorusCompanyLoading) {
    return <Loading />;
  } else if (errorDetails?.serverFault && errorDetails) {
    const usersWithBunkerVessel: User[] = companyFleet!;
    if (errorDetails.statusCode == 400) {
      const users = usersWithBunkerVessel.map((subitem: User, i: any) => (
        <tr key={i}>
          <td scope="row">{subitem.firstName}</td>
          <td>{subitem.lastName}</td>
          <td>{subitem.email}</td>
        </tr>
      ));
      //reload data after some time
      setTimeout(() => {
        getCompanyFleetCallback();
      }, 15000);
      return (
        <CustomError
          errorTitle={'Not allowed to delete vessel'}
          errorMessage={'There are users in your company that are associated with this vessel:'}
          displayContact={false}
        >
          <Table style={{ display: 'initial' }}>
            <thead>
              <tr>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>{users}</tbody>
            <h6 style={{ marginTop: '20px' }}> Refresh page in order to continue</h6>
          </Table>
        </CustomError>
      );
    }
    return <Error error={errorDetails} />;
  } else if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else if (chorusCompanyError && chorusCompanyErrorDetails) {
    return <Error error={chorusCompanyErrorDetails} />;
  } else if (selectedBunkerShipId != '') {
    return (
      <EditBunkership
        auth={auth}
        selectedBunkerShipId={selectedBunkerShipId}
        goBack={() => {
          displayEditBunkerFor('');
        }}
        serviceLayer={serviceLayer}
      />
    );
  } else {
    return (
      <>
        {mapMyFleet ? (
          <>
            {newFleetItem()}

            <div style={{ height: '80vh', overflowX: 'auto', paddingRight: '5px' }}>
              {myFleets!.length < 1 && !newFleet ? (
                <div>
                  <div className={fleetCardStyles.addVessels}>Add a fleet to get started</div>
                  <img src={arrowUp} alt="" className={fleetCardStyles.addVesselsImg} />
                </div>
              ) : null}

              {myFleets!.map((item, index) => (
                <MyFleetCard
                  key={index}
                  deleteFleet={deleteFleet!}
                  chooseVesselMethod={chooseVessel}
                  deleteVesselMethod={deleteVesselMethod}
                  fleet={item}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <Label>
              {!isSupplier ? 'Add vessels used in the nominations and edit their technological specifications.' : ''}
            </Label>
            {displaySearchVesselCustomized && isSupplier && (
              <SearchVesselCustomized
                chooseVesselMethod={chooseVessel}
                setDisplaySearchVessel={setDisplaySearchVesselCustomized}
                isSearchActive={isSearchVesselCustomizedActive}
                setIsSearchActive={setSearchVesselCustomizedActive}
              />
            )}
            {!displaySearchVesselCustomized && isSupplier && (
              <SearchVessel
                chooseVesselMethod={chooseVessel}
                isCompanyAdmin={isCompanyAdmin}
                isSupplier={isSupplier}
                companyCharteredFleet={companyCharteredFleet}
              />
            )}
            {displaySearchVesselCustomized && !isSupplier && (
              <SearchVessel
                chooseVesselMethod={chooseVessel}
                isCompanyAdmin={isCompanyAdmin}
                isSupplier={isSupplier}
                companyCharteredFleet={companyCharteredFleet}
              />
            )}
            {companyFleet && companyFleet.length ? (
              <StyledTableWrapper>
                <FuelBossTableContainer
                  className={className}
                  headers={headersNew}
                  tableRows={companyFleet}
                  hiddenColumns={!isSupplier ? ['LNG quality specification'] : undefined}
                  showFilterSearch={false}
                />
              </StyledTableWrapper>
            ) : null}
            <SearchCharterVessel
              chooseVesselMethod={addCharteredFleetVesselMethod}
              isCompanyAdmin={isCompanyAdmin}
              companyFleet={companyFleet}
              companyCharteredFleet={companyCharteredFleet}
            />
            {companyCharteredFleet && companyCharteredFleet.length ? (
              <StyledTableWrapper>
                <FuelBossTableContainer
                  className={className}
                  headers={headersChartered}
                  tableRows={companyCharteredFleet}
                  showFilterSearch={false}
                />
              </StyledTableWrapper>
            ) : (
              <div style={{ fontWeight: 'bold', paddingLeft: '1px', color: '#24365c' }}>No chartered vessels.</div>
            )}
          </>
        )}
      </>
    );
  }
};

export default React.memo(MyFleet);
