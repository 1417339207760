import { faFilePdf, faPencil, faShare, faUsers } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'

import {
  getNominationStatusIcon,
  getUserNegotiationPermissions,
  Icon,
  INegotiationPermissions,
  INominationEnquiryEvent,
  instanceOfINominationEnquiryEvent,
  instanceOfNominationTimelineItem,
  IPromptNomination,
  isCustomer,
  isScheduler,
  isSchedulerCaptain,
  IUserProfile,
  NegotiationState,
  UserRole,
  useUpdateEffect
} from '@teqplay/chorus-components'
import { Button, ButtonGroup, Dialog } from '@teqplay/teqplay-ui'
import React, { useEffect, useState } from 'react'
import ActionDialogContent from './ActionDialogContent'
import OnBehalfDialogContent from './OnBehalfDialogContent'
import { RecurringNomination } from '../../../../../store/RecurringNomination/models'
import { instanceOfRecurringNomination } from '../Validators/recurringNominationValidation'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../store'
export type IFuelbossNegotiationPermissions = INegotiationPermissions & {canDelete: boolean}

interface NegotiationHeaderProps {
  initialEvent: IPromptNomination | INominationEnquiryEvent | RecurringNomination
  selectedEvent: IPromptNomination | INominationEnquiryEvent | RecurringNomination
  isNominationInSandbox: boolean
  readOnly: boolean
  userRoles: UserRole[] | undefined
  userProfile: IUserProfile
  isDifferentFromInitial: boolean
  error?: string
  customerCompanyName: string
  delegatedCompanyName?: string
  setContractCandidateEvent?: (event?: IPromptNomination | INominationEnquiryEvent | RecurringNomination) => void
  updateEvent?: (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE' | 'DELETE',
    updatedNomination: IPromptNomination | RecurringNomination
  ) => void
  changeStateWhenValid: (state: NegotiationState, onBehalf: boolean, reason?: string) => void
  activateEditMode: (type: 'EDIT' | 'EDIT_ON_BEHALF') => void
  acceptPendingNomination?: (pendingNomination: IPromptNomination | RecurringNomination) => void
  generateAndSendOrderConfirmation?: () => void
  cancelDelegatedNomination?: (reason?: string) => void

  // Optional callbacks for DNV
  onOrderPDFConfirmed?: () => void
  // Optional for delegation container
  delegationBtnEnabled?: boolean
  delegatedNominationEventId?: string
  setDelegationState?: (
    state: 'EDITING_SN' | 'EDITING_AN' | 'CREATING_DELEGATION' | 'OVERVIEW'
  ) => void
  nominationType?: 'SELECTED_NOMINATION' | 'ASSOCIATED_NOMINATION'
}

const pencilIcon = findIconDefinition({ prefix: 'fal', iconName: 'pencil' });
const shareIcon = findIconDefinition({ prefix: 'fal', iconName: 'share' });
const usersIcon = findIconDefinition({ prefix: 'fal', iconName: 'users' });
const filePdfIcon = findIconDefinition({ prefix: 'fal', iconName: 'file-pdf' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const trashIcon = findIconDefinition({ prefix: 'fal', iconName: 'trash' });


const NegotiationActions: React.FunctionComponent<NegotiationHeaderProps> = props => {
  const [activeDialog, setActiveDialog] = useState<
    'ACCEPT' | 'REJECT' | 'CANCEL' | 'COMPLETE' | 'FINALISE' | 'ON_BEHALF' | 'CANCEL_DELEGATION' | 'DELETE'
  >()
  
  const isRecurringNomination = instanceOfRecurringNomination(props.initialEvent);
  //@ts-ignore
  const isLatestEvent = isRecurringNomination ? props.initialEvent._id === props.selectedEvent._id : true;

  const eventDescriptionText = instanceOfINominationEnquiryEvent(props.selectedEvent)
    ? 'Enquiry'
    : 'Nomination'

  const getRecurringNominationNegotiationPermissions = (
    event: RecurringNomination, eventIsLatest: boolean, userProfile: IUserProfile, isEventInSandbox: boolean, isGodMode?: boolean | undefined
  ): IFuelbossNegotiationPermissions => {
    if(isCustomer(userProfile.roles)){
      return {canEdit: true, canDelete: true} as IFuelbossNegotiationPermissions;
    }
    else{
      return {canEdit: true} as IFuelbossNegotiationPermissions;
    }
  }
  
  const negotiationPermissions = !isRecurringNomination ? getUserNegotiationPermissions(
    //@ts-ignore
    props.selectedEvent,
    isLatestEvent,
    props.userProfile,
    props.isNominationInSandbox
  ) as IFuelbossNegotiationPermissions: getRecurringNominationNegotiationPermissions(
    //@ts-ignore
    props.selectedEvent,
    isLatestEvent,
    props.userProfile,
    props.isNominationInSandbox
  );


  if (props.readOnly === true) {
    return null
  }

  return (
    <>
      <ButtonGroup>
        {negotiationPermissions.canEdit && (
          <Button
            preventDoubleClick
            iconButton
            className="edit-button"
            disabled={props.readOnly}
            onClick={enableEditMode}
            primary
            tooltipText={'Edit'}
          >
            <Icon>
              <FontAwesomeIcon icon={pencilIcon} />
            </Icon>
          </Button>
        )}

        {/* Order PDF Confirmation, Generation and Emailing */}
        {isOrderConfirmationButtonVisible() && (
          <Button
            preventDoubleClick
            iconButton
            className="edit-button"
            disabled={props.readOnly}
            onClick={async () => {
              props.generateAndSendOrderConfirmation &&
                (await props.generateAndSendOrderConfirmation())

              // Callback function
              props.onOrderPDFConfirmed && props.onOrderPDFConfirmed()
            }}
            primary
            tooltipText={'Generate Order Confirmation PDF'}
          >
            <Icon>
              <FontAwesomeIcon icon={filePdfIcon} />
            </Icon>
          </Button>
        )}

        {negotiationPermissions.canEdit &&
          (isScheduler(props.userRoles) || isSchedulerCaptain(props.userRoles)) &&
          !!props.delegatedNominationEventId &&
          instanceOfNominationTimelineItem(props.selectedEvent) && (
            <Button
              preventDoubleClick
              iconButton
              className="on-behalf-button"
              disabled={props.readOnly}
              onClick={() => setActiveDialog('ON_BEHALF')}
              primary
              tooltipText={'Edit for customer'}
            >
              <Icon>
                <FontAwesomeIcon icon={usersIcon} />
              </Icon>
            </Button>
          )}

        {negotiationPermissions.canAccept && (
          <Button
            preventDoubleClick
            primary
            iconButton
            className="approve-button"
            tooltipText={'Approve'}
            onClick={() => setActiveDialog('ACCEPT')}
          >
            {getDialogTitleIcon('ACCEPT')}
          </Button>
        )}

        {negotiationPermissions.canComplete && (
          <Button
            preventDoubleClick
            primary
            iconButton
            className="complete-button"
            tooltipText={'Complete'}
            onClick={() => setActiveDialog('COMPLETE')}
          >
            {getDialogTitleIcon('COMPLETE')}
          </Button>
        )}

        {negotiationPermissions.canFinalise && (
          <Button
            preventDoubleClick
            primary
            iconButton
            className="finalise-button"
            tooltipText={'Confirm and Finalise'}
            onClick={() => setActiveDialog('FINALISE')}
          >
            {getDialogTitleIcon('FINALISE')}
          </Button>
        )}

        {negotiationPermissions.canAssignEnquiryContract && props.setContractCandidateEvent && (
          <Button
            primary
            preventDoubleClick
            iconButton
            className="add-contract-button"
            tooltipText={'Add Contract'}
            onClick={() =>
              props.setContractCandidateEvent
                ? props.setContractCandidateEvent(props.selectedEvent)
                : undefined
            }
          >
            {getDialogTitleIcon('ADD_CONTRACT')}
          </Button>
        )}

        {negotiationPermissions.canReject && (
          <Button
            preventDoubleClick
            danger
            iconButton
            className="reject-button"
            tooltipText={'Reject'}
            onClick={() => setActiveDialog('REJECT')}
          >
            {getDialogTitleIcon('REJECT')}
          </Button>
        )}

        {negotiationPermissions.canCancel && (
          <Button
            preventDoubleClick
            danger
            iconButton
            className="cancel-button"
            tooltipText={'Cancel'}
            onClick={() => setActiveDialog('CANCEL')}
          >
            {getDialogTitleIcon('CANCEL')}
          </Button>
        )}
      {negotiationPermissions.canDelete && (
          <Button
            preventDoubleClick
            danger
            iconButton
            className="delete-button"
            tooltipText={'Delete'}
            onClick={() => setActiveDialog('DELETE')}
          >
            {getDialogTitleIcon('DELETE')}
          </Button>
        )}

        {negotiationPermissions.canCancelDelegation && (
          <Button
            preventDoubleClick
            danger
            iconButton
            className="cancel-button"
            tooltipText={'Un-delegate'}
            onClick={() => setActiveDialog('CANCEL_DELEGATION')}
          >
            {getDialogTitleIcon('CANCEL_DELEGATION')}
          </Button>
        )}

        {negotiationPermissions.canDelegate &&
          props.setDelegationState &&
          props.delegationBtnEnabled && (
            <Button
              preventDoubleClick
              iconButton
              className="delegation-button"
              disabled={props.readOnly}
              onClick={startCreateDelegation}
              primary
              tooltipText={'Add Delegation'}
            >
              {getDialogTitleIcon('DELEGATE')}
            </Button>
          )}
      </ButtonGroup>

      {activeDialog && (
        <Dialog
          type="medium"
          titleIcon={getDialogTitleIcon(activeDialog)}
          title={
            activeDialog === 'CANCEL_DELEGATION'
              ? 'Un-delegate bunkering'
              : activeDialog === 'ON_BEHALF'
              ? 'Submit for Customer'
              : `${activeDialog.toLowerCase()} ${eventDescriptionText}`
          }
          onCloseDialog={() => {
            setActiveDialog(undefined)
          }}
          showDialog={activeDialog !== undefined}
        >
          {activeDialog === 'ON_BEHALF' && (
            <OnBehalfDialogContent
              permissions={negotiationPermissions}
              changeStateWhenValid={props.changeStateWhenValid}
              error={props.error}
              activateEditMode={props.activateEditMode}
              setActiveDialog={setActiveDialog}
              customerCompanyName={props.customerCompanyName}
            />
          )}

          {activeDialog !== 'ON_BEHALF' && (
            <ActionDialogContent
              initialEvent={props.initialEvent}
              selectedEvent={props.selectedEvent}
              permissions={negotiationPermissions}
              setActiveDialog={setActiveDialog}
              acceptPendingNomination={props.acceptPendingNomination}
              error={props.error}
              updateEvent={props.updateEvent}
              action={activeDialog}
              changeStateWhenValid={props.changeStateWhenValid}
              delegatedCompanyName={props.delegatedCompanyName}
              cancelDelegatedNomination={props.cancelDelegatedNomination}
            />
          )}
        </Dialog>
      )}
    </>
  )

  function isOrderConfirmationButtonVisible() {
    // generateAndSendOrderConfirmation is a function provided by useNominationService
    if (!props.generateAndSendOrderConfirmation) return false

    if (!props.selectedEvent.state) return false

    if (!['ACCEPTED', 'FINALISED', 'COMPLETED'].includes(props.selectedEvent.state)) return false

    // // Finally, check for user role
    if (isScheduler(props.userRoles) || isSchedulerCaptain(props.userRoles)) return true

    return false
  }

  function getDialogTitleIcon(
    action:
      | 'ACCEPT'
      | 'REJECT'
      | 'CANCEL'
      | 'COMPLETE'
      | 'FINALISE'
      | 'ADD_CONTRACT'
      | 'ON_BEHALF'
      | 'DELEGATE'
      | 'CANCEL_DELEGATION'
      | 'DELETE'
  ) {
    switch (action) {
      case 'ACCEPT':
        return getNominationStatusIcon('ACCEPTED')
      case 'REJECT':
        return getNominationStatusIcon('REJECTED')
      case 'CANCEL':
        return getNominationStatusIcon('CANCELLED')
      case 'COMPLETE':
        return getNominationStatusIcon('COMPLETED')
      case 'FINALISE':
        return getNominationStatusIcon('FINALISED')
      case 'ADD_CONTRACT':
        return getNominationStatusIcon('NEEDS_CONTRACT')
      case 'ON_BEHALF':
        return <FontAwesomeIcon icon={usersIcon} />
      case 'DELEGATE':
        return (
          <Icon>
            <FontAwesomeIcon icon={shareIcon} />
          </Icon>
        )
      case 'CANCEL_DELEGATION':
        return (
          <Icon>
            <span className="stripe-through-icon"></span>
            <FontAwesomeIcon icon={shareIcon} />
          </Icon>
        )
      case 'DELETE':
        return (
          <Icon>
            <FontAwesomeIcon icon={trashIcon} />
          </Icon>
        )
      default:
        return null
    }
  }
  function startCreateDelegation() {
    if (!props.setDelegationState) return
    props.setDelegationState('CREATING_DELEGATION')
  }
  function enableEditMode() {
    props.activateEditMode('EDIT')
    if (!props.setDelegationState) return
    if (props.nominationType === 'SELECTED_NOMINATION') {
      props.setDelegationState('EDITING_SN')
    }
    if (props.nominationType === 'ASSOCIATED_NOMINATION') {
      props.setDelegationState('EDITING_AN')
    }
  }
}

export default React.memo(NegotiationActions)
