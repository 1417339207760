import React, { useCallback } from 'react';
import { User } from '../../../../../store/Users/models';
import { ErrorActionPayload } from '../../../../../models/ErrorAction';
import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { StyledTableWrapper } from '../../../StyledComponents/StyledTableWrapper';
import FuelBossTableContainer from '../../../../FuelBossTable';
import { TableOptions, Column, Accessor, UseTableOptions } from 'react-table/index';
import DeleteById from '../../../../DeleteById/DeleteById';
import EditById from '../../../../EditById/EditById';
import styles from './Users.module.scss';
import { useState } from 'react';
import { checkSystemAdmin, checkCompanyAdmin } from '../../../../../helpers/roleChecks';
import { ApplicationState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { access } from 'fs';
import { getUsers } from '../../../../../store/Users/actions';

const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const syncIcon = findIconDefinition({ prefix: 'fal', iconName: 'sync' });

interface OwnProps {
  users: User[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteUser: (id: string) => void;
  editUser: (user: User, roles: Role[]) => void;
  companyAdmin?: boolean;
  company?: Company;
  navAdminType: string;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
}

const addUserIcon = findIconDefinition({
  prefix: 'fal',
  iconName: 'user-plus',
});

const Users: React.FC<OwnProps> = ({
  users,
  isLoading,
  hasError,
  errorDetails,
  deleteUser,
  editUser,
  companyAdmin,
  company,
  navAdminType,
  defaultSelectedPage,
  setDefaultSelectedPage,
}) => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));

  const headersNew: Array<Column<User>> = React.useMemo(
    () => [
      {
        Header: 'First name',
        accessor: 'firstName',
      },
      {
        Header: 'Last name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Company',
        accessor: (row, index) => row.company.name,
      },
      {
        Header: 'Roles',
        accessor: (user) => {
          let roles = '';
          user.userRoles.forEach((ur, i) => {
            if (ur.role) {
              roles += ur.role.roleType;
              if (i !== user.userRoles.length - 1) {
                roles += ', ';
              }
            }
          });
          return roles;
        },
      },
      {
        Header: 'Active',
        accessor: (row) => {
          if (row.active) return 'Yes';
          else return 'No';
        },
      },
      //only show this collumn if the user is system admin
      ...(auth && checkSystemAdmin(auth)
      ? [
          {
            Header: 'Veracity Subscribed',
            accessor: (row: User) => {
              if (row.veracitySubscription) return 'Yes';
              else return 'No';
            },
          },
        ]
      : []),
      ...(auth && checkSystemAdmin(auth)
      ? [
          {
            Header: 'Veracity Verified',
            accessor: (row: User) => {
              if (row.veracityVerified) return 'Yes';
              else return 'No';
            },
          },
        ]
      : []),
      ...(auth && checkSystemAdmin(auth)
      ? [
          {
            Header: 'Creation date (FuelBoss)',
            accessor: (row: User) => {
              if (row.createdAt) return row.createdAt;
              else return '';
            },
            Cell: (props: any) => {
              const data = props.cell.row.original;
              return (
                <div className="date-cell">
                  {data.createdAt ? new Date(data.createdAt).toLocaleString() : ''}
                </div>
              );
            }
          },
        ]
      : []),
      ...(auth && checkSystemAdmin(auth)
        ? [
            {
              Header: 'Delete',
              Cell: (props: any) => {
                const data = props.cell.row.original;
                return (
                  <div className="delete-cell">
                    <DeleteById
                      id={data.id ? data.id : ''}
                      deleteMethod={deleteUser}
                      header="Delete user"
                      delBtnIcon={deleteIcon}
                    />
                  </div>
                );
              },
            },
          ]
        : []),
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const user = props.cell.row.original;
          return (
            <div className="edit-cell">
              <EditById redirectUrl={`/${navAdminType}admin/users/${user.id}/edit`} />
            </div>
          );
        },
      },
      {
        Header: 'Fleet Vessel',
        accessor: (row, index) => row.fleetVessel?.vessel.vesselName,
        hidden: true,
      },
      {
        Header: 'Fleet Pipeline',
        accessor: (row, index) => row.fleetPipeline?.name,
        hidden: true,
      },
    ],
    [auth],
  );

  const history = useHistory();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const getSyncUsersUrl = '/api/UserAsset/UserSync/';
  const sendUserSyncRequest = async () => {
    toggle();
    const UserSyncResponse = await fetch(getSyncUsersUrl);
    if (!UserSyncResponse.ok) {
      var errorMessage = `Failed to run sync users. Status: `;
      if (UserSyncResponse.status === 401) {
        errorMessage = errorMessage + 'Not authorized to run sync users';
      } else if (UserSyncResponse.status === 404) {
        errorMessage = errorMessage + 'function not found';
      } else {
        errorMessage = errorMessage + `${UserSyncResponse.status} ${UserSyncResponse.statusText}`;
      }
      console.error(errorMessage);
      return;
    }
  };

  const dispatch = useDispatch();
  const getUsersCallback = useCallback(() => dispatch(getUsers()), []);
  const [modalVeracityStatusSync, setModalVeracityStatusSync] = useState(false);
  const getStatusSyncUrl = '/api/UserAsset/VeracityStatusSync/';
  const sendVeracityStatusSyncRequest = async () => {
    setModalVeracityStatusSync(true);
    const statusSyncResponse = await fetch(getStatusSyncUrl);
    if (!statusSyncResponse.ok) {
      var errorMessage = `Failed to run sync users. Status: `;
      if (statusSyncResponse.status === 401) {
        errorMessage = errorMessage + 'Not authorized to run sync statuses';
      } else if (statusSyncResponse.status === 404) {
        errorMessage = errorMessage + 'function not found';
      } else {
        errorMessage = errorMessage + `${statusSyncResponse.status} ${statusSyncResponse.statusText}`;
      }
      console.error(errorMessage);
      return;
    }else{
      setModalVeracityStatusSync(false);
      getUsersCallback();
    }
  };

  if (isLoading) {
    return <Loading />;
  } else if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else if (companyAdmin && company) {
    const filteredUsers = users && users.length && users.filter((user) => user.company.companyId === company.companyId);

    return (
      <StyledTableWrapper>
        <Button color="primary" size="lg" onClick={() => history.push(`/${navAdminType}admin/users/add`)}>
          <FontAwesomeIcon icon={addUserIcon} /> Add team member
        </Button>
        {filteredUsers && filteredUsers.length ? (
          <FuelBossTableContainer
            headers={headersNew}
            tableRows={filteredUsers}
            hiddenColumns={['Fleet Vessel', 'Fleet Pipeline']}
            showPagination={true}
            defaultSelectedPage={defaultSelectedPage}
            setDefaultSelectedPage={setDefaultSelectedPage}
          />
        ) : null}
      </StyledTableWrapper>
    );
  } else {
    return (
      <StyledTableWrapper>
        <div className={styles['buttons']}>
          <Button outline color="info" size="lg" onClick={sendVeracityStatusSyncRequest}>
            <FontAwesomeIcon icon={syncIcon} /> Veracity status sync
          </Button>
          <Button outline color="info" size="lg" onClick={sendUserSyncRequest}>
            <FontAwesomeIcon icon={syncIcon} /> Run user sync
          </Button>
          <Button color="primary" size="lg" onClick={() => history.push(`/${navAdminType}admin/users/add`)}>
            <FontAwesomeIcon icon={addUserIcon} /> Add team member
          </Button>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>User sync running</ModalHeader>
            <ModalBody>
              <h4>Email notification with list of users that needs attention has been sent</h4>
              <Button color="primary" onClick={toggle}>
                OK
              </Button>
            </ModalBody>
          </Modal>
          <Modal isOpen={modalVeracityStatusSync} toggle={undefined}>
            <ModalHeader toggle={undefined}>Status sync running</ModalHeader>
            <ModalBody>
              <h4>Veracity status sync is running please wait</h4>
            </ModalBody>
          </Modal>
        </div>
        {users && users.length ? (
          <FuelBossTableContainer
            headers={headersNew}
            tableRows={users}
            hiddenColumns={['Fleet Vessel', 'Fleet Pipeline']}
            showPagination={true}
            defaultSelectedPage={defaultSelectedPage}
            setDefaultSelectedPage={setDefaultSelectedPage}
          />
        ) : null}
      </StyledTableWrapper>
    );
  }
};

export default React.memo(Users);
